body{
    background-color: #F2F2F2;
}

@keyframes surge {
    0%   {opacity: 5%;}
    25%   {opacity: 25%;}
    50%   {opacity: 50%;}
    75%   {opacity: 75%;}
    99%   {opacity: 99%;}

}

@keyframes progressSurge {
    0%   {opacity: 0%;}
    5%   {opacity: 0%;}
    15%   {opacity: 0%;}
    25%   {opacity: 0%;}
    35%   {opacity: 0%;}
    45%   {opacity: 0%;}
    55%   {opacity: 5%;}
    65%   {opacity: 25%;}
    75%   {opacity: 50%;}
    85%   {opacity: 75%;}
    99%   {opacity: 99%;}

}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.descrptContainer{
    display: flex;
    justify-content: center;
    animation-name: surge;
    animation-duration: 0.5s;
    
}

.descrptArea{

    background-color: white;
    width: 34.75rem;
    margin-right: 1.5rem;
    padding: 1.5rem;
    border-radius: 16px;


}

.descrptArea h3{
    position: absolute;
    font-size: 15px;
    margin-top: -1.5rem;
    margin-left: 0.5rem;
    color: red;
}

.descrptArea h4{
    color: #4C0040;
    font-family: "Montserrat";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;


}


.loadingBarArea{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;


}

.loadingBarContainer{
    width: 33.125rem;
}

.loadingLine{
    width: 33.125rem;
    top: 4.05rem;
    display: flex;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -2;
    transform: translateY(-50%);
    position: absolute;
    height: 0.25rem;
    background-color: #CCCCCC;
}

.greenLine{
    width: 0px;
    align-self: baseline;
    z-index: 6;
    height: 0.25rem;
    background-color: #00A480;
    transition: width 0.5s ease-out;
}

.loadingCirclesContainer{
    margin-top: 3.4rem;
    width: max-content;
    display: flex;
    justify-content: baseline;

}

.loadingCirclesDescript{
    margin-left: -1px;
    width: 632.38px;
    display: flex;
    justify-content: space-between;
}

.loadingCirclesDescript h4{
    text-align: center;
    width: 6.75rem;
    font-family: Roboto Flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    color: #666666;
}

.paymentRightContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;


}

.nextPageContainer{
    display: flex;
    justify-content: space-between;
}

.nextBtn{
    border: none;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 11.813rem;
    height: 3rem;
    background-color: #4C0040;
    color: #FFECF5;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    border-radius: 16px;
    font-weight: 600;
    line-height: 115%;
    transition: 0.2s;
}

.nextBtn:hover{
    color: #F1963A;
}

.prevBtn{
    border: none;
    margin-top: 1rem;
    width: 11.813rem;
    height: 3rem;
    background-color: #F2F2F2;
    color: #4C0040;
    border: 1px solid #4C0040;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    border-radius: 16px;
    font-weight: 600;
    line-height: 115%;
    transition: 0.2s;
}

.prevBtn:hover{

    color: #F1963A;
}


.deniBtn{
    border: none;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 35rem; 
    height: 4rem;
    font-size: 18px;
    background-color: #4C0040;
    color: #FFECF5;
    font-family: "Montserrat";
    font-style: normal;
    border-radius: 16px;
    font-weight: 600;
    line-height: 115%;
    transition: 0.2s;
}

.deniBtn:hover{
    color: #F1963A;
}



.loadingArea{

    margin-top: 5rem;
    display: flex;
    flex-direction: column;

}

.loadingCircle{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 6.6rem;
    background-color: #CCCCCC;
    border-radius: 50px;
}

.loadingCircleLast{
    width: 1.5rem;
    height: 1.5rem;

    background-color: #CCCCCC;
    border-radius: 50px;
}

.greenCircle{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 5rem;
    display: flex;
    justify-content: center;
    z-index: 6;
    justify-content: center;
    align-items: center;
    background-color: #00A480;
    border-radius: 50px;   
    animation-name: progressSurge;
    animation-duration: 0.75s;
}



.greenCircle label{
    color: #E2F3FF;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    transition: 0.5s;
}

.teamIcon{
    overflow-wrap: break-word;
    display: flex;
    height: 2rem;
    width: max-content;
    max-width: 100%;
    border-radius: 12px;
    padding: 8px 12px;
    align-items: center;
    
    background-color: #F2F2F2;
}

.teamIcon img{
    width: 2em;
    height: 2rem;
    margin-right: 12px;
}

.teamIcon h5{
    font-family: "Roboto Flex";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    color: #4D4D4Ded;
    margin-right: 4px;
}

.teamIcon h4{
    font-family: "Roboto Flex";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    color: #4C0040;
}


.team_image{
    width: 100%;
    height: 15.625rem;
}

.teamIcon{
    display: flex;
    align-items: center;
}


.teamInfo{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.teamInfo h4{
    font-family: "Roboto Flex";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #666666;
}

.teamInfo h5{
    font-family: "Roboto Flex";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; 
    color: #666666;
}

.descrptContainer h6{
    margin-top: 1.5rem;
    font-family: Roboto Flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #666666;
}

.lineBreak{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    height: 2px;
    background-color: #F2F2F2;
}


.paymentInfo{
    width: 21.688rem;
    height: max-content;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 16px;
    background-color: white;
    overflow-wrap: break-word;
}

.paymentInfo h3{
    margin-top: 0.5rem;
    font-family: "Montserrat";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;  
    color: #1A1A1A;
}

.paymentInfoItem{

    display: flex;
    height: 3rem;
    align-items: center;
    justify-content: space-between;
}

.paymentInfoItem h4{
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #666666;
}

.paymentInfoQuant{
    margin: 0px;
    display: flex;
    align-items: center;
}

.paymentInfoQuant h5{
    text-align: center;
    margin: 0px;
    height: max-content;
    min-width: 2rem;
}

.paymentInfoQuantTwo{
    display: flex;
    align-items: flex-end;
}


.paymentInfoQuantTwo h5{
    text-align: center;

}

.textInput{
    width: 93%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 1px solid #666666;
    border-radius: 12px;
    display: flex;
    padding: 12px 16px;
    align-items: flex-start;
    font-family: "Roboto Flex";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
}

.selectTextInput{
    width: 48.2%;
    border: 1px solid #666666;
    border-radius: 12px;
    display: flex;
    padding: 12px 16px;
    align-items: flex-start;
    font-family: "Roboto Flex";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;   
}


.twinInput{
    width: 99.2%;
    display: flex;
    justify-content: space-between;

}

.twinInput input{
    width: 42%;
    margin-top: 0rem;
    margin-bottom: 0rem;
    border: 1px solid #666666;
    border-radius: 12px;
    display: flex;
    padding: 12px 16px;
    align-items: flex-start;
    font-family: "Roboto Flex";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
}


.areaRadio{

    background-color: white;
    width: 34.75rem;
    margin-right: 1.5rem;
    padding: 1.5rem;
    border-radius: 16px;


}


form{
    display: flex;
    
}

.radioInput{
    width: 20px;

    border: 1px solid red;
    text-decoration: none;
    box-shadow: none;
}

.paymentPageRadio{
    display: flex;
    align-items: center;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border-radius: 40px;
    border: 1px solid #666666;   
    margin-right: 0.5rem;
}

.paymentPageRadio label{
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-weight: 600;
    line-height: 120%;
}

.paymentPageRadio input{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;

    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid #bbbbbb;
    background-color: white;
    border-radius: 50%;
    margin: 0px;
    height: 18px;

    width: 18px;

}


.paymentPageRadio input:hover{
    border: 2px solid #4C0040;
    padding: 3x;
    background-color: #e7e6e7;
}

.paymentPageRadio input:checked{
    border: 2px solid #4C0040;
    padding: 3px;
    background-color: #4C0040;
}



.pageTwoTitle{
    margin-top: 0px;
    margin-bottom: 2rem;
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #4C0040;
}

.descrptArea input:focus{
    outline: 1px solid #4C0040; 
}


.btnPlus{
    margin: 0;
    height: 32px;
    width: 24px;
    color: #4C0040;
    font-size: 30px;
    border: none;
    background-color: transparent;
}

.btnMinus{
    margin-bottom: 7px;
    height: 32px;
    width: 24px;
    color: #4C0040;
    font-size: 30px;
    border: none;
    background-color: transparent;
}

.paymentInfoItem h5{
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #4C0040;
}

.paymentContainer{
    margin-top: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.paymentContainer h5{
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #666666;
}



.paymentType{
    display: flex;
    transition: 0.2s;
}

.payment{
    display: flex;
    width: 5rem;
    align-items: center;
    flex-direction: column;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    transition: 0.2s;
}

.payment h5{
    margin-top: 5px;
    font-family: Roboto Flex;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    color: #666666;
    transition: 0.2s;
}

.payment img{

    height: 1.5rem;
    width: 1.5rem;
    transition: 0.2s;
}

.paymentFooter{
    align-items: center;
    display: flex;
    margin-bottom: 17rem;
    justify-content: center;
}

.paymentFooter h5{
    margin-top: 2rem;
    width: 75%;
    text-align: center;
    font-family: Roboto Flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    color: #666666;
}

.cardData{

    animation-name: surge;
    animation-duration: 0.5s;
}

.notFoundDiv{
    min-height: 100vh;
    width: 100%;

}

.notFoundArea{
    display: flex;
    height: 45rem;
    justify-content: center;
    padding-bottom: 13rem;
}

.notFoundContainer{
    display: flex;
    padding-bottom: 5rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.notFoundContainer img{
    margin-top: 5rem;
    width: 20.625rem;
    height: 10.875rem;
}

.notFoundContainer h4{
    margin-bottom: 1.5rem;
    width: 80%;
    font-family: "Montserrat";
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #4C0040;
}

.notFoundContainer h5{
    margin-top: 0.4rem;
    width: 75%;
    text-align: center;
    font-family: "Roboto Flex";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    color: #666666;
}

.popUpDenied{
    position: fixed;
    height: 35rem;
    width: 40rem;
    background-color: white;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9;
    border-radius: 20px;
    transform: translateY(-50%);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.popUpDenied img{
    width: 15.625rem;
    height: 14.375rem;
}

.popUpDenied h3{
    font-family: "Montserrat";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #4C0040;
    margin-bottom: 10px;
}

.popUpDenied h2{
    width: 80%;
    font-family: "Roboto Flex";
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #666666;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-top: 0px;
}

.popUpDenied h4{
    font-family: "Roboto Flex";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #666666;
    margin-bottom: 10px;
    margin-top: 0px;
}

.popUpDenied h5{
    font-family: Roboto Flex;
    margin-top: 0px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #666666;
}

.dimmerArea{
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.418);
    z-index: 9;
}

.selectPaymentInstallments{

    width: 99.2%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 1px solid #666666;
    border-radius: 12px;
    display: flex;
    padding: 12px 16px;
    align-items: flex-start;
    font-family: "Roboto Flex";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 

}

.mobilePaymentInfo{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100vw;
    height: 11.5rem;
    z-index: 8;
    position: fixed;
    bottom: 0;

}

.mobilePaymentSubContainer{
    padding: 1rem;
}

.mobileButtonContainer{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.paymentMobileItem{

    display: flex;
    height: 2rem;
    align-items: center;
    justify-content: space-between;
}

.paymentMobileItem h4{
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin: 0px;
    color: #666666;
}

.paymentMobileItem{
    display: flex;
    align-items: center;
}

.paymentMobileItem h5{
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin: 0px;
    color: #4C0040;
}

.prevBtnMobile{
    border: none;
    margin-top: 1rem;
    width: 9rem;
    height: 2.5rem;
    font-size: 12px;
    background-color: #F2F2F2;
    color: #4C0040;
    border: 1px solid #4C0040;
    font-family: "Montserrat";
    font-style: normal;
    border-radius: 16px;
    font-weight: 600;
    line-height: 115%;
    animation-name: surge;
    animation-duration: 0.5s;
}

.prevBtnMobile:hover{

    color: #F1963A;
}



.nextBtnMobile{
    border: none;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 9rem;
    height: 2.5rem;
    font-size: 12px;
    background-color: #4C0040;
    color: #FFECF5;
    font-family: "Montserrat";
    font-style: normal;
    border-radius: 16px;
    font-weight: 600;
    line-height: 115%;
    animation-name: surge;
    animation-duration: 0.5s;
}

.nextBtnMobile:hover{
    color: #F1963A;
}

.mobileEmpty{
    height: 10rem;
}

.pixPaymentContainer{

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}

.pixPaymentContainer h2{
    color: #4C0040;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; 

}

.pixPaymentSubContainer{
    width: 30rem;
    margin-top: 2rem;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 2rem;
    padding: 2rem;

}

.pixCopyContainer{
    background-color: #FFECF5;
    width: 90%;
    border-radius: 1rem;
    display: flex;
    padding: 0.5rem;
    justify-content: space-between;
    align-items: center;

}

.pixCopyContainer h3{
    color: #442461;
    font-family: Roboto Flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 

}

.refuseBtn{
    border: none;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 30.813rem;
    height: 4rem;
    background-color: #4C0040;
    color: #FFECF5;
    font-family: "Montserrat";
    font-size: 18px;
    font-style: normal;
    border-radius: 16px;
    font-weight: 600;
    line-height: 115%;
    transition: 0.2s;
}

.refuseBtn:hover{
    color: #F1963A;
}


.copyPixBtn{
    border: none;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 11.813rem;
    height: 3rem;
    background-color: #4C0040;
    color: #FFECF5;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    border-radius: 16px;
    font-weight: 600;
    line-height: 115%;
    transition: 0.2s;
    outline: none;

}

.copyPixBtn:hover{
color: #F1963A;

}

.pixPaymentSubContainer h3{
    font-family: Roboto Flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    color: #666666;

}

.pixValueContainer{
    display: flex;
    margin-top: 1.5rem;
    width: 98%;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    align-items: center;
    justify-content: space-between;

}

.pixValueContainer h3{
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;

}

.pixValueContainer h4{
    font-family: Montserrat;
    margin: 0px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #1A1A1A;

}

.pixPaymentSubContainer h4{
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0px;
    line-height: 120%; 
    color: #666666;

}

.pixPaymentSubContainer h2{
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    margin-bottom: 25px;
    font-weight: 600;
    line-height: 120%; 
    color: #666666;

}

.pixPaymentSubContainer h5{
    font-family: Roboto Flex;
    font-size: 16px;
    font-style: normal;
    margin-top: 0px;
    margin-bottom: 10px;
    font-weight: 400;
    line-height: 150%;
    color: #666666;
    
}

.installContainer{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items:baseline;
}

.installContainer h3{
    margin: 0px;
    font-family: Roboto Flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 1rem;
}

.creditCardTitle h2{
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #4C0040;
}


.creditCardContainer{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

}

.creditCardContainer h3{
    margin: 0px;
    font-family: "Montserrat";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.creditCardContainer h4{
    margin: 0px;
    font-family: "Montserrat";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #1A1A1A;
}

.footerPix{
    width: 30rem;
    margin-top: 1rem;
    text-align: left;
}
.footerPixLabel{
    margin-top: 1rem;
    display: flex;
    justify-content: left;
    align-items: center;
    justify-items: center;
}

.linkBoleto{
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-decoration: none;
    line-height: 115%;
    color: #4C0040;
}

.footerPixLabel label{
    font-family: "Roboto Flex";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #4C0040;
}

.footerPixLabel h5{
    font-family: "Roboto Flex";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #4C0040;
}

.footerPixCircle{
    min-width: 1.5rem;
    min-height: 1.5rem;
    margin-right: 1rem;
    display: flex;
    z-index: 6;
    justify-content: center;
    align-items: center;
    border: 2px solid #4C0040;
    border-radius: 50px;   
    animation-name: progressSurge;
    animation-duration: 0.75s;
}

.footerPixCircle label{
    color: #4C0040;
    font-family: Roboto Flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.footerPix h5{
    font-family: "Roboto Flex";
    font-size: 14px;
    margin: 0px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #4C0040;
}

.pixFooterLine{
    width: 90%;
    height: 1px;
    border-bottom: 2px solid #CCCCCC;
    margin-bottom: 2rem;
    margin-top: 2rem;
}


@media (max-width: 1050px) {

    .descrptContainer{

        flex-direction: column;
        align-items: center;
    }

    .descrptArea{
        margin: 0px;
    }

    .paymentInfo{
        width: 34.75rem;
        margin-top: 1.5rem;
    }

    .pageTwoTitle{
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .areaRadio{
        margin-right: 0rem;
    }

}



@media (max-width: 645px) {
    
    .refuseBtn{
        width: 80%;
    }

    .pageTwoTitle{

        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        width: 85%;
        font-size: 28px;
    }

    .descrptContainer{
          width: 100%      
    }

    .paymentRightContainer{
        width: 100%;
        margin-top: 2.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    

    .descrptArea{
        padding-left: 1rem;
        padding-right: 1rem;
        width: 85%;
        margin: 0px;
    }

    .descrptSubContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .textInput{
        width: 93%;
        padding: 0.5rem;
        margin-left: 0rem;
        margin-right: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 10px;
    }


    .twinInput{
        width: 96.8%;
        display: flex;
        justify-content: space-between;

    }


    .twinInput input{
    width: 40%;
    height: 1rem;
    padding: 0.5rem;
    margin-left: 0rem;
    margin-right: 0rem;
    font-size: 10px;

    }


    .payInfoContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .payInfoSubContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }

    .paymentInfo{
        margin-top: 0px;
        font-size: 20px;
        width: 81.8%;
    }

    .teamIcon{
        width: max-content;
    }

    .teamIcon h4{
        font-size: 12px;
    }

    .teamIcon h5{
        font-size: 12px;
    }

    .nextPageContainer{
        margin-top: 1rem;
        width: 90%;

    }

    .nextBtn{
        width: 9rem;
        height: 2.5rem;
        font-size: 12px;
    }

    .prevBtn{
        width: 9rem;
        height: 2.5rem;
        font-size: 12px;
    }

    .descrptArea h3{
        position: absolute;
        font-size: 10px;
        margin-top: -0.95rem;
        margin-left: 0.5rem;
        color: red;
    }
    
    .loadingBarArea{
        width: 100vw;
    }

    .loadingBarContainer{
        width: 90%;
        position: absolute;
        margin-top: -8.4rem;
        justify-content: center;

    }

    .loadingCirclesContainer{
        width: 80%;
        justify-content: space-between;
        margin-top: 3rem;
        
    }

    .loadingCirclesDescript{
        width: 88%;
    }

    .loadingCircle{
        margin: 0px;
    }

    .loadingCirclesDescript h4{
        font-size: 10px;
        width: 4rem;
    }

    .greenCircle label{
        font-size: 14px;
        width: 10px;
    }


    .loadingLine{
        width: 82%;
        height: 0.4rem;
    }

    .greenLine{
        height: 0.4rem;
    }

    .areaRadio{
        width: 82%;

    }

    .areaRadio label{
        font-size: 10px;
    }

    .selectPaymentInstallments{
        width: 98%;
        padding: 0.5rem;
        margin-left: 0rem;
        margin-right: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 10px;
    }

    .pixPaymentSubContainer{
        width: 80%;

    }


    .pixCopyContainer{

        flex-direction: column;
        background-color: white;
    }
    

    .pixCopyContainer h3{
        background-color: #F2F2F2;
        padding: 1rem;
        margin-top: 0px;
        border-radius: 5px;
    }


    .popUpDenied{
        
        height: 35rem;
        width: 80%;
        
    }

    .popUpDenied img{
        width: 50%;
        height: 40%;
    }

    .popUpDenied h3{

        font-size: 28px;

    }

    .popUpDenied h2{
        width: 80%;
        font-size: 26px;

    }

    .popUpDenied h4{
        width: 60%;
        text-align: center;
        font-size: 16px;

    }

    .deniBtn{
        width: 80%;

    }



    .popUpDenied h5{
        font-family: Roboto Flex;
        margin-top: 0px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: #666666;
    }


    .pixPaymentSubContainer h2{
        font-size: 20px;
        margin-bottom: 3rem;
    }

    .footerPix{
        width: 80%;
    }

    .pixPaymentSubContainer h5{
        font-size: 12px;
    }

    .footerPixLabel h5{
        font-size: 12px;
    }



}

@media (max-width: 500px) {
    .twinInput{
        width: 97.5%;

    }

    .teamInfo h4{
        font-size: 14px;
    }

    .teamIcon h5{
        font-size: 14px;
    }

    .areaRadio label{
        font-size: 7px;
    }

    .areaRadio label{
        padding: 0px;
        padding-bottom: 0.2rem;
        padding-left: 0.2rem;
        padding-right: 0.25rem;
        font-size: 8px;
    }

    .popUpDenied h2{
        width: 80%;
        font-size: 22px;

    }

    .popUpDenied h5{
        margin-top: 0.5rem;
        width: 80%;
        text-align: center;
        font-size: 16px;

    }



    form{
        display: flex;
        justify-content: left;
    }

    .creditCardTitle h2{
        font-size: 28px;
    }

    .paymentPageRadio input{
        width: 15px;
        height: 15px;
    }

    .paymentPageRadio{
        margin-right: 0.2rem;
    }

    .paymentPageRadio img{
        height: 15px;
    }

    .radioImgDiv{
        height: max-content;
    }
}

@media (max-width: 420px){
    .teamInfo{
        flex-direction: column;
        align-items: baseline;
    }

    .teamInfo h4{
        margin-bottom: 0px;
        margin-top: 2rem;

    }

    .refuseBtn{

        font-size: 16px;
    }
    
    .teamInfo h5{

        margin-top: 1rem;
        margin-bottom: 2rem;
    }
}

@media (max-width: 400px) {

    .loadingBarContainer{
        margin-top: -7.75rem;
    }
    .loadingCirclesDescript h4{
        font-size: 8px;
        width: 3rem;
    }

    .loadingLine{
        width: 80%;
        height: 0.5rem;
    }

    .greenCircle label{
        font-size: 12px;
    }

    .pageTwoTitle{
        font-size: 22px;
    }

    .greenLine{
        height: 0.5rem;
    }

    .twinInput{
        width: 97.5%;

    }

    .twinInput{
        width: 98.5%;

    }
    
    .popUpDenied{
        height: 28rem;
    }

    .popUpDenied h3{
        width: 80%;
        font-size: 20px;
        text-align: center;

    }

    .popUpDenied h4{
        width: 80%;
        text-align: center;
        font-size: 14px;

    }

    .popUpDenied h5{
        width: 80%;
        text-align: center;
        font-size: 14px;

    }

    .deniBtn{
        width: 70%;
        height: 3.5rem;
        font-size: 14px;
    }

    .popUpDenied img{
        width: 40%;
        height: 30%;
    }

    .paymentPageRadio img{
        height: 12px;
        width: 12px;
    }

    .paymentPageRadio input{
        height: 12px;
        width: 12px;
    }

    .paymentPageRadio label{
        padding: 0.25rem;

        font-size: 7px;
    }


}
