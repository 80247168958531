@keyframes example {
    0%   {background-color: #ffffff00;}
    10%  {background-color: #ffffff10;}
    20%  {background-color: #ffffff20;}
    30% {background-color: #ffffff30;}
    40%   {background-color: #ffffff40;}
    50%  {background-color: #ffffff50;}
    60%  {background-color: #ffffff60;}
    70% {background-color: #ffffff70;}
    80% {background-color: #ffffff80;}
    90% {background-color: #ffffff90;}
    100% {background-color: #ffffff;}
}


.mainDivContainer{
    position: fixed;
    background-color: white;
    width: 100vw;
    z-index: 4;
    top: 0;
}

.popUpMenu{
    position: fixed;
    top: 4.3rem;
    left: 1rem;
    z-index: 8;
    width: 14.938rem;
    height: 10.75rem;
    background-color: white;
    animation-name: example;
    animation-duration: 0.1s;
    border-radius: 12px;
    display: flex;
    flex-direction: column;

}

.popUpLink{
    margin-top: 11px;
    margin-left: 25px;
    margin-bottom: 11px;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    font-family: "Roboto";
    color: #1A1A1A;
    transition: 0.2s;
}

.popUpLink:hover{
    color: #c424ac;
}

.popUpMenu Link h4{
    text-decoration: none;
    color: black;
}


.mainDiv{
    display: flex;
    height: 3.53175rem;
    align-content: center;   
    justify-content: space-between;

}

.subDivLeft{
    margin-left: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.subDivRight{
    margin-right: 2.5rem;
    display: flex;
    align-items: center;

}

.menuIcon{
    height: 1.2rem;
    width: 1.2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

}

.athleticLogo{
    margin-left: 1.5rem;
    margin-top: 0.5rem;
    height: 1.17725rem;
    width: 9.5rem;

}

.link{
    text-decoration: none;
    font-size: 14px;
    color: #4C0040;
    font-weight: 600;
    font-family: "Montserrat";
    transition: 0.2s;
    
}

.link:hover{
    color: #c424ac;
}

.userArea{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    align-items: center;

}

.menuNavBar{
    background-color: transparent;
    border: none;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0;
    margin-top: 0.25rem;
    background-image: url("../../public/images/menu-burger.png");
    transition: 0.2s;

}

.menuNavBar:hover{
    background: url("../../public/images/menu-burger-hover.png") no-repeat;
}

.username{
    text-decoration: none;
    color: #4C0040;
    font-size: 14px;
    font-weight: 600;
    font-family: "Montserrat";
    
}

@media (max-width: 628px) {

    .popUpMenuMobile{
        position: fixed;
        z-index: 8;
        width: 55%;
        height: 95vh;
        display: flex;
        flex-direction: column;
        transition: 0.5s; 
        padding: 20px;
        background-color: white;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        top: 0;
        left: 0;
    }

    .popUpMenuMobile h4{
        margin-top: 0px;
        font-size: 20px;
        color: #1A1A1A;
        font-weight: 600;
    }


    .popUpLinkMobile{
        margin-top: 11px;
        margin-bottom: 11px;
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        font-family: "Roboto Flex";
        color: #1A1A1A;   
        transition: 0.2s;
    }

    .popUpLinkMobile:hover{
        color: #c424ac;
    }



    .dimmerArea{
        position: absolute;
        top: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.418);
        z-index: 7;
    }
    
    .closeMenu{
        background-image: url("../../public/images/close-button.png");
        background-position: center;
        background-size: 2rem 2rem;
        position: absolute;
        top: 0.6rem;
        padding: 1.4rem;
        margin-left: 50vw;
        height: 2rem;
        width: 2rem;
        background-color: transparent;
        border: none;
        transition: 0.2s;
    }

    .closeMenu:hover{
        background-image: url("../../public/images/close-button-hover.png");
    }
    


      
}
@media (max-width: 500px) {

    .link{
        font-size: 12px;


    
    }

    .closeMenu{
        margin-left: 48vw;
    }

    .subDivRight{
        margin-right: 2rem;

    
    }

    .athleticLogo{
        margin-left: 1rem;
        height: 0.8rem;
        width: 7.5rem;
    
    }



}

@media (max-width: 400px) {
    .subDivRight{
        margin-right: 1rem;

    }
}

