.btn{
    border: none;
    min-width: 11rem;
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 3rem;
    background-color: #4C0040;
    color: #FFECF5;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    border-radius: 12px;
    font-weight: 600;
    line-height: 115%;
    transition: 0.2s;
}

.btn:hover{
    color: #F1963A;
}