.mainDiv{
    margin-top: 5.5rem;
    min-height: 86vh;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.titleDiv{
    display: flex;
    width: 37.75rem;
    justify-content: left;
    align-items: center;
    text-decoration: none;

}

.titleText{
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    color: #4C0040;
    font-family: "Montserrat";
    text-decoration: none;
}

.subtitleDiv{
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    letter-spacing: 1px;
    display: flex;
    width: 37.75rem;
    font-size: 20px;
    font-family: "Roboto flex";
    justify-content: left;
    align-items: center;
    font-weight: 400;
    color: #4C0040;
}

.subtitleDiv h1{
    margin-top: 0px;
}

h1{
    font-family: "Roboto Flex";
    text-align: left;
    font-size: 24px;
    line-height: 24px;
}

h2{
    font-family: "Roboto Flex";
    font-size: 20px;
    line-height: 24px;
}

h3{

}

p{
    font-family: "Roboto Flex";
    line-height: 24px;
    font-size: 16px;
}

li{
    font-family: "Roboto Flex";
    line-height: 24px;
    font-size: 16px;
}



.termsContent{
    width: 34.75rem;
    background-color: white;
    padding: 1.5rem;
    margin-bottom: 20rem;
    padding-bottom: 0px;
    border-radius: 16px;
}



@media (max-width: 620px) {

    .titleDiv{
        width: 90%;
    }

    .subtitleDiv{
        width: 90%;
    }

    .termsContent{
        width: 83%;
        padding: 1rem;
    }

}