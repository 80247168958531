

.mainDiv{
    width: 100%;
    bottom: 0;
    height: 253.2px;
    background-color: #1B0019;
    border-radius: 24px 24px 0px 0px;

}

.footerBackgroundContainer{
    position: relative;

}

.backgroundSocial{
    display: flex;
    margin-top: 1rem;
    justify-content: center;

}

.leftBlur{
    height: 100px;
    width: 100px;
    bottom: 0;
    margin-bottom: 12.73rem;
    position: absolute;

}


.leftBlur img{
    height: 300px;
    width: 300px;
    bottom: 0;
}

.rightBlur{
    height: 100px;
    width: 100px;
    bottom: 0;
    right: 0;
    margin-right: 25rem;
    margin-bottom: 9.55rem;
    position: absolute;
}

.rightBlur img{
    border-top-right-radius: 24px;
    height: 243.2px;
    width: 500px;
    bottom: 0;
}

.rightBlurHome{
    height: 100px;
    width: 100px;
    bottom: 0;
    right: 0;
    margin-right: 25rem;
    margin-bottom: 20.55rem;
    position: absolute;
}

.rightBlurHome img{
    border-top-right-radius: 24px;
    height: 243.2px;
    width: 500px;
    bottom: 0;
}


.instagramIcon{
    background-image: url("../../public/images/icon-instagram.png");
    background-size: 2rem 2rem;
    height: 2rem;
    margin-left: 7.5px;
    margin-right: 7.5px;
    width: 2rem;
    transition: 0.2s;
}

.instagramIcon:hover{
    background-image: url("../../public/images/icon-instagram-hover.png");
    background-size: 2rem 2rem;
}

.whatsappIcon{
    background-image: url("../../public/images/icon-whatsapp.png");
    background-size: 2rem 2rem;
    height: 2rem;
    margin-left: 7.5px;
    margin-right: 7.5px;
    width: 2rem;
    transition: 0.2s;
}

.whatsappIcon:hover{
    background-image: url("../../public/images/icon-whatsapp-hover.png");
    background-size: 2rem 2rem;
}

.linkedinIcon{
    background: url("../../public/images/icon-linkedin.png") ;
    background-size: 2rem 2rem;
    height: 2rem;
    margin-left: 7.5px;
    margin-right: 7.5px;
    width: 2rem;
    transition: 0.2s;
}

.linkedinIcon:hover{
    background: url("../../public/images/icon-linkedin-hover.png") ;
    background-size: 2rem 2rem;
}

.backgroundFooter{
    bottom: 0;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 26.188rem;
}



.footer{
    display: flex;
    margin-top: 0px;
    justify-content: center;
}

.footer img{
    margin-top: 3.75rem;
    width: 14.709rem;
    height: 1.823rem;
}


.policyContainer{
    display: flex;
    justify-content: center;
    text-decoration: none;
    margin-top: 1rem;
    padding-bottom: 4.8rem;
}

.policyLink{
    text-decoration: none;
    margin-right: 12px;
    color: white;
    font-family: "Montserrat";
    font-size: 14;
    font-weight: 600;
    margin-left: 12px;
    transition: 0.2s;
}

.policyLink:hover{
    color: #F1963A;
}
