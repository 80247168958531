

.mainDiv{
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    width: 19.625rem;
    height: 12.25rem;
    border-radius: 1rem;
    margin-bottom: 3rem;
    background-color: white;

}

.titleDiv{
    width: 17rem;
    height: 3rem;
    margin-top: 1.5rem;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleDiv h4{
    margin:0px;
    width: 13.5rem;
    padding: 0px;
    text-align: left;
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: 600;
}

.retangle{
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    width: 3rem;
    height: 3rem;
    background-color: #FFECF5;
    border-radius: 14.4px;
}

.retangle img{
    width: 2.5rem;
    height: 2.5em;

}

.doubleTitleRetangle{
    width: 3rem;
    height: 3rem;
    margin-top: 0.25rem;
    background-color: #4C004014;
    border-radius: 12px;
}

.doubleTitleDiv{
    width: 16.625rem;
    height: 3rem;
    max-height: 16.625rem;
    max-height: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 0px;

    display: flex;
    text-align: center;
    align-content: center;
    justify-content: space-between;
}

.doubleTitleDiv h4{
    margin: 0;
    text-align: left;
    max-width: 13.265rem;
    width: 13.265rem;
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: 600;
}

.mainDiv h5{
    width: 16.625rem;
    text-align: left;
    margin-top: 1rem;
    font-family: "Roboto";
    font-size: 14px;
    max-height: 5.25rem;
    line-height: 21px;
    font-weight: 400;
    color: #1A1A1A;
}

@media (max-width: 1080px) {
    .mainDiv{
        width: 16rem;
        height: 17.5rem;
        margin-bottom: 2rem;
    }

    .titleDiv{
        width: 13rem;
        height: 3rem;

    }

    .titleDiv h4{
        max-width: 10rem;

        margin-top: 0px;

    }

    .mainDiv h5{
        width: 13rem;
        
    }

}

@media (max-width: 900px) {
    .mainDiv{
        width: 32%;
        height: 18.5rem;

    }

    .titleDiv{
        width: 90%;
        height: 3rem;

    }

    .titleDiv h4{
        font-size: 16px;
        margin-top: 0px;

    }

    .mainDiv h5{
        font-size: 16px;
        line-height: 24px;
        width: 90%;
        
    }

}

@media (max-width: 757px) {
    .mainDiv{
        width: 48%;
        height: 17.5rem;

    }

    .titleDiv{
        width: 85%;
        height: 3rem;

    }

    .titleDiv h4{
        font-size: 16px;
        width: 85%;
        margin-top: 0px;

    }

    .mainDiv h5{
        font-size: 16px;
        line-height: 24px;
        width: 85%;
        
    }

}

@media (max-width: 628px) {
    .mainDiv{
        margin-left: 0.4rem;
        width: 98%;
        height: 17.5rem;

    }

    .titleDiv{
        width: 85%;
        height: 4rem;

    }

    .titleDiv h4{

        font-size: 20px;
        width: 70%;
        max-width: 80%;
        margin-top: 0px;

    }

    .mainDiv h5{
        font-size: 16px;
        line-height: 30px;
        width: 85%;
        
    }

    .retangle{
        margin-top: 0px;
        width: 4rem;
        height: 4rem;
    }
    
    .retangle img{

        width: 4rem;
        height: 4rem;
    }

}

@media (max-width: 500px) {
    .mainDiv{
        height: 16rem;

    }

    
}