
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.loaderContainer{
    position: fixed;
    background-color: #F2F2F2;
    z-index: 12;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    border: 16px solid white; /* Light grey */
    border-top: 16px solid #4C0040; /* Blue */
    border-radius: 50%;
    z-index: 13;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }
  