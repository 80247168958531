
.failMsg{
    position: fixed;
    top: 0;
    width: 32.5em;
    height: max-content;
    text-align: center;
    color: red;
    font-family: "Roboto";
    font-size: 22px;
    padding: 20px;
    border-radius: 5px;
    background-color: rgb(250, 200, 200); 
    margin: auto;
    margin-top: 8rem;
    left: 0;
    right: 0;
    transform: translateY(-50%);

}

.failMsg h3{
    margin-top: 0px;
    margin-bottom: 0px;
}


.sucessMsg{
    position: fixed;
    top: 0;
    width: 32.5em;
    height: max-content;
    text-align: center;
    color: green;
    font-family: "Roboto";
    font-size: 22px;
    padding: 20px;
    border-radius: 5px;
    background-color: rgb(188, 247, 160);
    margin: auto;
    margin-top: 8rem;
    left: 0;
    right: 0;
    transform: translateY(-50%);

}


.sucessMsg h3{
    margin-top: 0px;
    margin-bottom: 0px;
}


@media (max-width: 757px) {

    .failMsg{
        width: 85%;
    }

    .sucessMsg{
        width: 85%;
    }
}

@media (max-width: 500px) {

    .failMsg{
        font-size: 17px;
    }

    .failMsg{
        font-size: 17px;
    }
}

