.mainDiv{
    display: flex;
    flex-direction: column;
}

.subDiv{
    height: 62rem;
    display: flex;
    flex-direction: column;

    align-items: center;
}

.subDiv img{
    margin-top: 10rem;
    width: 28.625rem;
    height: 14.875rem;
}

.subDiv h3{
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #4C0040;
}


.nextBtn{
    border: none;
    text-decoration: none;
    display: flex;
    
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 11.813rem;
    height: 3rem;
    background-color: #4C0040;
    color: #FFECF5;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    border-radius: 16px;
    font-weight: 600;
    line-height: 115%;
    transition: 0.2s;
}

.nextBtn:hover{
    color: #F1963A;
}
