
.mainDiv{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.mainSubDiv{

    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mainSubDiv h2{
    font-family: "Montserrat";
    text-align: center;
    width: 38rem;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #4C0040;
}

.mainSubDiv h4{
    width: 38rem;
    text-align: center;
    font-family: "Roboto Flex";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    color: #666666;
}

.prevBtn{
    border: none;
    margin-top: 1rem;
    margin-right: 1rem;
    min-width: 11rem;
    height: 3rem;
    background-color: transparent;
    color: #4C0040;
    border: 1px solid #4C0040;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    border-radius: 12px;
    font-weight: 600;
    line-height: 115%;
    transition: 0.2s;
}

.prevBtn:hover{

    color: #F1963A;
}

.highlightedText{
    
    margin-top: 0px;
    width: 38rem;
    text-align: center;
    font-family: "Roboto Flex";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    background-color: transparent;
    color: #4C0040;
}

.textInput{
    width: 93%;
    margin-top: 1rem;
    border: 1px solid #666666;
    border-radius: 12px;
    display: flex;
    padding: 12px 16px;
    align-items: flex-start;
    font-family: "Roboto Flex";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
}

.emailArea{
    width: 38rem;
    padding: 1rem;
    background-color: white;
    border-radius: 16px;
}

.emailArea h5{
    margin-top: 2px;
    margin-left: 1rem;
    font-family: "Roboto Flex";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;  
    color: #666666;
}

.btnExcluir{
    display: flex;
    width: 99%;
    margin-bottom: 0.5rem;
    justify-content: flex-end;
}

.mainSubDiv img{
    width: 257px;
    height: 155px;
}

.warningDiv{
    margin-top: 6rem;
    width: 38rem;
    background-color: #EBEBEB;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 12px;
}

.warningDiv h4{
    margin: 0px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-family: "Roboto Flex";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}


.checkArea{

    width: 38rem;
    padding: 1rem;
    padding-left: 2rem;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 16px;
}

.checkAreaSub{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 15rem;
}

.checkAreaSubTwo{
    display: flex;
    justify-content: space-between;
    margin-left: 0.7rem;
    flex-direction: column;
    height: 15rem;
}

.checkArea h3{
    font-family: "Roboto Flex";
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #666666;
}

.checkboxAreaOld{
    margin-bottom: 1rem;
    display: flex;
    justify-content: baseline;
    align-items: center;
}



.checkTextArea{
    margin-top: 0.5rem;
    height: 4rem;
    padding-top: 15px;
    padding-left: 15px;
    width: 96%;
    border-radius: 10px;
    border: 1px solid #CCCCCC;
    outline: #4C0040;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
}

.textPlaceholder{
    height: 5.7rem;
}

.checkboxArea {
    display: flex;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkboxArea label{
    font-family: "Roboto Flex";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #4C0040;
}
  
  .checkboxArea input {
    position: absolute;
    margin-left: -2.27rem;
    margin-top: -0.1rem;
    opacity: 0;
    z-index: 2;
    background-color: rebeccapurple;
    cursor: pointer;
    height: 29px;
    width: 29px;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    margin-top: 0.2rem;
    height: 15px;
    width: 15px;
    outline: 2px solid #4C0040;
    background-color: transparent;
  }
  
  .checkboxArea input:checked ~ .checkmark {
    background-color: #4C0040;
    color: white;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkboxArea input:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkboxArea .checkmark:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  
.checkboxAreaTwo {
    display: flex;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkboxAreaTwo label{
    font-family: "Roboto Flex";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #4C0040;
}
  
  
  .checkboxAreaTwo input {
    position: absolute;
    margin-left: -2.27rem;
    margin-top: 0.4rem;
    opacity: 0;
    z-index: 2;
    background-color: rebeccapurple;
    cursor: pointer;
    height: 29px;
    width: 29px;
  }
  
  .checkmarkTwo {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    margin-top: 0.6em;
    height: 15px;
    width: 15px;
    outline: 2px solid #4C0040;
    background-color: transparent;
  }
  
  .checkboxAreaTwo input:checked ~ .checkmarkTwo {
    background-color: #4C0040;
    color: white;
  }
  
  .checkmarkTwo:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkboxAreaTwo input:checked ~ .checkmarkTwo:after {
    display: block;
  }
  
  .checkboxAreaTwo .checkmarkTwo:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

.disableBtn{
    border: none;
    min-width: 11rem;
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 3rem;
    background-color: #F2F2F2;
    color: #CCCCCC;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    border-radius: 12px;
    font-weight: 600;
    line-height: 115%;

}

.ReSendButton{
    background-color: transparent;
    border: none;
    font-family: "Roboto Flex";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    color: #4C0040;
    cursor: pointer;
}


.warningInfoDiv{
    display: flex;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-bottom: 1.5rem;
    align-items: center;
    justify-content: space-between;
    background-color: #FAEBED;
    border-radius: 10px;
}


.warningInfoDiv img{
    height: 24px;
    width: 24px;
}

.warningInfoDiv h4{
    font-family: "Roboto Flex";
    font-size: 16px;
    font-style: normal;
    width: 93%;
    margin-top:  0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 120%;
    color: #6A131D;
}






.notFound{
    display: flex;
    flex-direction: column;
}

.notFoundSubDiv{
    height: 62rem;
    display: flex;
    flex-direction: column;

    align-items: center;
}

.notFoundSubDiv img{
    margin-top: 10rem;
    width: 28.625rem;
    height: 14.875rem;
}

.notFoundSubDiv h3{
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #4C0040;
}


.nextBtn{
    border: none;
    text-decoration: none;
    display: flex;
    
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 11.813rem;
    height: 3rem;
    background-color: #4C0040;
    color: #FFECF5;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    border-radius: 16px;
    font-weight: 600;
    line-height: 115%;
    transition: 0.2s;
}

.nextBtn:hover{
    color: #F1963A;
}


@media (max-width: 670px) {

    .mainSubDiv{
        width: 100%;
        margin-top: 5rem;
    }

    .mainSubDiv h2{
        width: 90%;
        margin-top: 1rem;
        font-size: 20px;

    }
    .mainSubDiv h4{
        line-height: 150%; 
        width: 90%;
        font-size: 16px;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }

    
    .mainSubDiv img{
        width: 247px;
        height: 128px;
    }



    .emailArea{
        width: 90%;
    }

    .warningDiv{
        width: 80%;
        margin-bottom: 1rem;
    }

    .checkArea{
        width: 80%;

    }

    .checkboxArea label{
        font-size: 14px;
    }

    .checkTextArea{
        width: 92%;
    }

    .checkAreaSubTwo{
        width: 80%;
        height: max-content;
    }

    .warningInfoDiv h4{
        width: 85%;
    }

    .deleteImg{
        width: 297px !important;
        height: 157px !important;

    }

    .btnExcluir{
        flex-direction: column;
    }

    .prevBtn{
        width: 100%;
    }

}


@media (max-width: 400px) {
    .emailArea{
        width: 85%;
    }

    .textInput{
        width: 90%;
    }
}
