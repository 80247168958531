html, body {
    overflow-x: hidden;
    scroll-behavior: smooth;  
  }

body{
    background-color: #F2F2F2;
    width: 100%;
    position: relative;


}


.subDivLanding{
    height: 35.688rem;
    border-radius: 0px 0px var(--spacing-and-sizing-4, 32px) var(--spacing-and-sizing-4, 32px);
    z-index: 1;
    background-color: #1B0019;
    width: 100%;
}

.subDivLanding img{
    height: 35.688rem;
    width: 100%;

}

.subDivLanding{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    
    
}

.backgroundImgLanding{
    position: absolute;
    z-index: 2;
    height: 32.813rem;
    width: 47.719rem;
    left: 0;
    right: 0;
    margin: auto;
    margin-right: 0rem;
    top: 19.25rem;
    transform: translateY(-50%);
}

.subDivLandingTitle{
    position: absolute;
    width: 23.688rem;
    top: 8.31rem;
    left: 8.06rem;
    z-index: 2;
}

.subDivLandingTitle h4{
    font-family: "Roboto";
    color: #FFECF5;
    font-weight: 700;
    font-size: 41px;
    margin: 0px;
 
}

.subDivLandingTitle h5{
    margin: 0px;
    margin-top: 1.5rem;
    line-height: 28px;
    color: white;
    font-size: 16px;
    font-weight: 400;

}



.download{
    margin-top: 2rem;
    width: 5.063rem;
    height: 1.225rem;
    background-color: #A9EEDB4A;
    border-radius: 5px;
    padding-bottom: 0.15rem;
    margin-bottom: 0.5rem;
    
}

.downloadText{
    text-decoration: none;
    margin: 0px;

    padding-left: 0.25rem;
    font-size: 0.75rem;
    font-family: "Montserrat";
    color: #7FE6CB;
    top:10px
}

.titleBackgroundBlur{
    position: absolute;
    top: 0;
    margin-left: -5rem;
    margin-top: -25rem;
    width: 632px;
    height: 632px;
    border-radius: 632px;
    background: #4C0040;
    filter: blur(219.5px);
    
}

.googlePlay{
    height: 3rem;
    width: 10.125rem;
    margin-right: 1rem;

}

.appStore{
    height: 3rem;
    width: 9rem;
}

.partnersArea{
    
    display: flex;
    flex-direction: column;
    margin-top: 10rem;
    justify-content: center;
    align-items: center;
}

.partnersArea h4{
    color: #666666;
    font-family: "Montserrat";
    font-size: 16px;
    line-height: 19.5px;
    font-weight: 600;
    letter-spacing: 1px;
}

.partnersContainer{
    display: flex;
    margin-top: 2.5rem;
    align-items: flex-start;
    scroll-behavior: smooth;
    overflow-x: hidden;
    width: 63.45rem;

}

.partner{
    display: flex;
    width: 15rem;
    flex-direction: column;
    align-items: center;
}

.partner img{
    width: 4rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    height: 4rem;
}

.partner h4{
    margin-bottom: 6px;
    text-align: center;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 400px;
}

.partner h5{
    background-color: #4C004014;
    border-radius: 4px;
    padding: 4px;
    margin-top: 0px;
    
    font-family: "Montserrat";
    font-weight: 400px;
    font-size: 12px;
    color: #B1569A;
}

.descrptArea{
    z-index: 2;
    margin-top: 30.5rem;
    position: relative;
    width: 100%;
}

.descrptContainer{

    position: absolute;
    z-index: -1;
    top: -13rem;
    width: 67rem;
    height: 25.688rem;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);

}

.atltDescrpt{
    position: absolute;
    width: 20.375rem;
    margin-left: 1rem;
    margin-top: -1.7rem;
    height: 23rem;

    
}

.descrpt{

    margin-left: 12.69rem;
    width: 52.313rem;
    height: 19.675rem;
    border-radius: 16px;
    background-color: #FFFFFF;
}

.descrpt h4{
    margin: 0px;
    padding-top: 2.5rem;
    margin-left: 12.88rem;
    color: #1A1A1A;
    font-family: "Montserrat";
    font-size: 36px;
    font-weight: 600;
    
}

.descrpt h5{
    margin: 0px;
    color: #1A1A1A;
    font: "Roboto";
    width: 37rem;
    margin-top: 1.5rem;
    margin-left: 12.88rem;
    font-size: 16px;
    font-weight: 500;
}

.downloadDescriptArea{

    margin-top: 1.5rem;
    width: 5.063rem;
    height: 1rem;
    margin-left: 12.88rem;
    background-color: #004434;
    padding-top: 0px;
    padding-bottom: 0.35rem;
    border-radius: 5px;
    margin-bottom: 0.5rem;

}

.descrptDownload{
    text-decoration: none;
    height: 0.5rem;
    font-size: 12px;
    margin: 0px;
    padding: 0px;
    padding-left: 0.25rem;
    font-size: 0.75rem;
    font-family: "Montserrat";
    color: #E5FFF5;

}


.googlePlayBlack{
    margin-top: 0.5rem;
    height: 3rem;
    z-index: 2;
    width: 10.125rem;
    margin-right: 1rem;
    margin-left: 12.88rem;
}

.appStoreBlack{
    margin-top: 0.5rem;
    z-index: 2;
    height: 3rem;
    width: 9rem;

}

.functionsTitle{
    margin-top: 67.25rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

}

.functionsTitle h4{
    font-size: 36px;
    margin-top: 0px;
    font-family: "Montserrat";
    font-weight: 600;
    color: #1A1A1A;
    margin-bottom: 0.2rem;
}

.functionsTitle h5{
    margin-top: 0.2rem;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 400;
    color: #1A1A1A;
    width: 30rem;
    margin-bottom: 0px;
    line-height: 24px;
}

.functionsArea{
    display: flex;
    margin-top: 3.75rem;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-around;
}

.functionContainer{
    width: 63.875rem;
    max-width: 63.875rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}


.placeHolderFunc{
    width: 19.625rem;
    height: 12.25rem;

}

.funcImg{
        z-index: -1;
        position: absolute;
        width: 36.4rem;
        height: 24.5rem;
        right: 0px;
        top: 86.5rem;

}

.addContainer01{

    align-items: center;
    margin-top: 22rem;
    text-align: center;
}

.addBackgroundContainer{

    position: relative;
    width: 100%;
    
}

.addBackground{
    position: absolute;
    z-index: -1;
    padding-left: 50px;
    width: 67rem;
    height: 25.688rem;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);

}

.addTextContainer{

    display: flex;
    width: 100%;
    justify-content: center;

}

.addTextSubContainer{
    margin-top: -190px;
    display: flex;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    flex-direction: column;

}

.addTextSubContainer h4{
    font-size: 66px;
    margin-bottom: 0px;
    font-family: "Montserrat";
    font-weight: 900;
    background: linear-gradient(#FFFFFF, #FFF1E1, #EEBEFF);
    background-clip: inherit;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}

.addTextSubContainer h5{
    margin-top: 0px;
    font-size: 20px;
    font-family: "Montserrat";
    color: white;
    font-weight: 500; 
}

.addDownloadAreaContainer{
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
    align-items: center;


}

.addDownloadContainer{
    width: 5.063rem;
    height: 1.025rem;

    background-color: #A9EEDB4A;
    border-radius: 5px;
    padding-bottom: 0.35rem;
    margin-bottom: 0rem;
    
}

.addDownloadIconContainer{
    margin-top: 1rem;

}

.addDownload{
    text-decoration: none;
    margin: 0px;
    padding-left: 0.25rem;
    
    padding-right: 0.25rem;
    font-size: 0.75rem;
    font-family: "Montserrat";
    color: #7FE6CB;
    top:10px
    
}

.googlePlayMain{
    height: 3rem;
    width: 10.125rem;
    padding-right: 0.5rem;

}

.appStoreMain{
    height: 3rem;
    width: 9rem;
    padding-left: 0.5rem;
    
}

.addMainContainer02{
    margin-top: 10.349rem;
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.addSubContainer02{
    width: 68rem;
    display: flex;

}

.add02Title{
    width: 35.002rem;
    margin-left: 4.5rem;
    text-align: left;
}

.add02Title h4{
    margin-top: 49.5px;
    font-size: 36px;
    font-family: "Montserrat";
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.add02Title h5{
    font-size: 16px;
    margin-top: 0px;
    font-family: "Roboto";
    font-weight: 400;
    width: 34rem;

}


.add02Image{
    width: 28.144rem;
    height: 21rem;
}

.add02DownloadContainer{

    margin-top: 1rem;
    width: 5.063rem;
    height: 1.225rem;
    padding-bottom: 0.15rem;
    background-color: #004434;
    border-radius: 5px;
    margin-bottom: 0.5rem;
}

.add02Download{
    text-decoration: none;
    margin: 0px;
    padding-left: 0.25rem;
    font-size: 0.75rem;
    font-family: "Montserrat";
    color: white;
    top:10px
    
}

.carouselContainer{
    margin-top: 3.388rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
}

.carouselTitle{
    
    display: flex;
    align-items: center;
    width: 64.3785rem;
    justify-content: space-between;

}

.carouselTitle h4{
    margin-top: 0px;
    margin-bottom: 0px;
    margin-bottom: 0px;

    font-family: "Montserrat";
    font-weight: 600;
    font-size: 36px;
    color: #1A1A1A;
}

.carousel{

    z-index: -2;
    width: 30.688rem;
    height: 14rem;
    border-radius: 16px;
    background-color: #FFFFFF;
    display: flex;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    justify-content: space-between;
    
}

.carouselStar{
    position: absolute;
    z-index: -2;
    margin-top: 2rem;
    margin: auto;
    margin-top: 26rem;
    left: 0;
    right: 64rem;
    transform: translateY(-40%);
}

.purpleButtonContainer{
    height: 3rem;
    width: 6.5rem;
    display: flex;
    justify-content: space-between;

}

.purpleButtonLeft{

    background-size: 3em 3rem;
    height: 3rem;
    border: 0px solid white;
    width: 3rem;
    background: url("../../public/images/purple-left-button.png");
}

.purpleButtonLeft:hover{
    background: url("../../public/images/purple-left-button-hover.png");   
}

.purpleButtonRight{

    background-size: 3em 3rem;
    height: 3rem;
    border: 0px solid white;
    width: 3rem;
    background: url("../../public/images/purple-right-button.png");
}

.purpleButtonRight:hover{
    background: url("../../public/images/purple-right-button-hover.png");
}

.carouselLeft{
    margin-top: 0.5rem;
    margin-left: 2.5rem;
    height: 14rem;
    width: 27rem;
    vertical-align: middle;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.carouselLeft h3{
    font-size: 20px;
    width: 90%;
    font-family: "Montserrat";
    font-weight: 600;
    color: #1A1A1A;
    text-align: left;

}

.carouselLeft img{
    width: 3.5rem;
    height: 3.5rem;
}

.carouselLeft h4{
    margin: 0px;
    margin-left: 1rem;
    margin-top: 0rem;
    font-size: 18px;
    font-family: "Montserrat";
    font-weight: 600;
    color: #666666;
}

.carouselLeft h5{
    margin-left: 1rem;
    margin-top: 0.1rem;
    font-size: 16px;
    font-family: "Montserrat";
    font-weight: 500;
    color: #666666;
}

.carouselContent{
    margin-top: 2.5rem;
    margin-left: -1rem;
    display: flex;
    width: 65rem;
    height: 17rem;
    scroll-behavior: smooth;
    overflow-x: hidden;
}


.carouselLeftAuthor{
    height: 5rem;
    display: flex;
}

.carouselAuthor{
    margin-right: 2.5rem;
    margin-top: 4.625rem;
    width: 17.313rem;
    text-align: center;
    height: 18rem;
}

.carouselAuthor h4{
    margin-top: 0px;
    font-family: "Montserrat";
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 600;
    color: #1A1A1A;
}


.carouselAuthor h5{

    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    color: #666666;
}

.greenStar{
    position: absolute;
    margin-top: 2.233em;
    margin-left: 10.8rem;
    z-index: -1;
    height: 12.188rem;
    width: 12.188rem;
}

.carouselAuthor img{
    height: 13.625rem;

    width: 13.625rem;
}

.sucessMsg{
    position: fixed;
    top: 0;
    width: 32.5em;
    height: 1.5em;
    text-align: center;
    color: green;
    font-family: "Roboto";
    font-size: 22px;
    padding: 20px 0px 20px;
    border-radius: 5px;
    background-color: rgb(188, 247, 160);
    margin: auto;
    margin-top: 8rem;
    z-index: 5;
    left: 0;
    right: 0;
    transform: translateY(-50%);

}

.sucessMsg h3{
    margin-top: 0px;
}

.failMsg{
    position: fixed;
    top: 0;
    width: 32.5em;
    height: 1.5em;
    text-align: center;
    color: red;
    font-family: "Roboto";
    font-size: 22px;
    padding: 20px 0px 20px;
    border-radius: 5px;
    background-color: rgb(250, 200, 200); 
    margin: auto;
    margin-top: 8rem;
    z-index: 5;
    left: 0;
    right: 0;
    transform: translateY(-50%);

}

.failMsg h3{
    margin-top: 0px;
}

.formContainer{
    margin-top: 6.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formTitle{
    text-align: center;
}

.formTitle h4{
    margin: 0px;

    font-family: "Montserrat";
    font-size: 36px;
    font-weight: 600;
}

.formTitle h5{
    margin: 0px;
    margin-top: 0.5rem;
    text-align: center;
    font-family: "Roboto";
    width: 27.75rem;
    font-size: 16px;
    font-weight: 400;
    color: #1A1A1A;
}

.formArea{
    margin-top: 1.5rem;
    z-index: 3;
    margin-bottom: 16rem;
    width: 28.063rem;
    height: 43.5rem;
    background-color: white;
    border-radius: 16px;
}

.formArea textarea{
    margin-top: 1.875rem;
    margin-left: 2.5rem;
    height: 8rem;
    padding-top: 15px;
    padding-left: 15px;
    width: 21.85rem;
    border-radius: 10px;
    border: 1px solid #CCCCCC;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
}

.formArea textarea:focus{
    outline: 1px solid #4C0040; 
}

.formAreaOne{
    margin-top: 3rem;
}

.formArea input, .formAreaOne{
    margin-top: 1.875rem;
    margin-left: 2.5rem;
    height: 3rem;
    padding: 0 15px;
    width: 21.063rem;
    border-radius: 10px;
    border: 1px solid #CCCCCC;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;

}


.formArea input:focus{
    outline: 1px solid #4C0040; 
}

.twoFormsLine{
    display: flex;
    justify-content: space-between;
    width: 23.063rem;
    margin-left: 2.5rem;
}


.twoFormsLine input{
    width: 8.781rem;
    margin-left: 0px;

}

.formButtonContainer{
    margin-right: 2.5rem;;
    display: flex;
    justify-content: flex-end;

}

.formButtonContainer button{
    margin-top: 2.5rem;
    background-color: #4C0040;
    color: #FFECF5;
    height: 3.3rem;
    border-radius: 10px;
    width: 7.875rem;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    border: none;
    outline:none;

}

.formButtonContainer button:hover{
    color: #F1963A;
}

.footerBackgroundContainer{
    position: relative;
}

.backgroundSocial{
    display: flex;
    margin-top: 1rem;
    justify-content: center;

}

.socialIcons{
    height: 2rem;
    margin-left: 7.5px;
    margin-right: 7.5px;
    width: 2rem;
}

.backgroundFooter{
    bottom: 0;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 26.188rem;
}

.footerBackground{
    position: absolute;
    bottom: 0;
    z-index: -1;
    background-color: #EBEBEB;
    height: 28.125rem;
    width: 100%;
}

.footer{
    display: flex;
    justify-content: center;
}

.footer img{
    margin-top: 3.75rem;
    width: 14.709rem;
    height: 1.823rem;
}


.policyContainer{
    display: flex;
    justify-content: center;
    text-decoration: none;
    margin-top: 1rem;
    padding-bottom: 5rem;
}

.policyLink{
    text-decoration: none;
    margin-right: 12px;
    color: white;
    font-family: "Montserrat";
    font-size: 14;
    font-weight: 600;
    margin-left: 12px;
}

@media (max-width: 1080px) {

    :root {
        --mainWidth: 52rem;

    }

    .backgroundLanding{
        height: 34.313rem;

    }

    .backgroundImgLanding{
        width: 38.438rem;
        top: 20.25rem;
        height: 30.688rem;
    }

    .subDivLandingTitle{
        left: 4.5rem;
    }

    .subDivLandingTitle h4{
        letter-spacing: 2px;
        width: 26rem;
        font-size: 36px;
    }

    .descrptContainer{
        width: var(--mainWidth);
    }
    
    .descrpt{

        margin-left: 10rem;
        width: 42.25rem;
        height: 21.313rem;
    }

    .descrpt h4{
        width: 28rem;
        margin-left: 8rem;
    }

    .descrpt h5{
        width: 33rem;
        margin-left: 8rem;
    }
   
    .atltDescrpt{
        margin-top: 2rem;
        margin-left: 0rem;
        width: 16.177rem;
        height: 16.694rem;
    }

    .downloadDescriptArea{
        margin-left: 8rem;
    }

    .googlePlayBlack{
        margin-left: 8rem;
  
    }

    .functionContainer{
        width: 51rem;

    }

    .functionContainer02{
        width: var(--mainWidth);
        max-width: 63.875rem;
        display: flex;
        margin-top: 2.5rem;
        justify-content: space-between;
    
    }

    .placeHolderFunc{
        width: 16rem;
        height: 17.5rem;
    
    }

    .funcImg{
        top: 94rem;
        width: 28rem;
        height: 27rem;
    }    

    .addContainer01{
        margin-top: 17rem;
    }


    .addBackground{
        width: var(--mainWidth);
    }


    .addSubContainer02{
        width: var(--mainWidth);
        display: flex;
    
    }

    .add02Title{
        margin-left: 2rem;
    }

    .add02Title h5{
        width: 29.009rem;

    }

    
    .add02Title h4{
        margin-top: 0px;
        width: 29.009rem;

    }

    .add02Image{
        margin-top: 2.434rem;
        width:  19.4914rem;
        height: 16.195rem;
    }

    .addSubContainer02{
        width: var(--mainWidth);
    }

    .carouselTitle{
        width: var(--mainWidth);
    }

    .carouselStar{

        margin: auto;
        margin-top: 27.5rem;
        left: 0;
        right: 49rem;
        transform: translateY(-40%);
    }
    

    .carouselContent{
        width: var(--mainWidth);

    }

    .carousel{
        width: 23rem;
        height: 16rem;
        
        
    }

    .carouselLeft{
        height: 16rem;
    }

    .carouselLeft{
        margin-left: 0.5rem;

    }

    .carouselLeft h3{
        margin-left: 10px;

    }

    .carouselLeftAuthor{
        margin-left: 10px;    
    }

    .partnersContainer{
        width: var(--mainWidth);
    }
}

@media (max-width: 900px) {

    :root {
        --mainWidth: 45rem;

    }

    .subDivLanding{
        height: 28.7rem;
    }

    .subDivLandingTitle{
        position: absolute;
        width: 23.688rem;
        top: 6.31rem;
        left: 2.06rem;
         z-index: 2;
    }

    .backgroundImgLanding{
        top: 16.25rem;
        width: 30.438rem;
        height: 24.688rem;
    }

    .subDivLanding img{
        height: 28.688rem;
        width: 100%;
    
    }

    .subDivLandingTitle h4{

        width: 23rem;
        font-size: 31px;
    }

    .subDivLandingTitle h5{
        font-size: 15px;
        width: 20rem;
    }

    .descrptContainer{  
        top: -11rem;
        width: 50rem;
    }


    .descrpt{
        margin-left: 4rem;
        width: 42.25rem;
        height: 20.125rem;
    }

    .descrpt h4{
        width: 28rem;
        font-size: 20px;
        margin-left: 8rem;
    }

    .descrpt h5{

        width: 31.5rem;
        margin-left: 8rem;
    }
   
    .atltDescrpt{
        margin-top: 4.5rem;
        margin-left: 0rem;
        width: 9.875rem;
        height: 11.148rem;
    }

    .downloadDescriptArea{
        margin-left: 8rem;
    }

    .googlePlayBlack{
        margin-left: 8rem;
  
    }

    .functionsTitle {
        margin-top: 58.25rem;

    }

    .functionsTitle h4{
        font-size: 20px;
    }
    

    .functionContainer{
        width: 95%;
    }

    .functionContainer02{
        width: 44rem;
        max-width: 63.875rem;
        display: flex;
        margin-top: 2.5rem;
        justify-content: space-between;
    
    }

    .placeHolderFunc{
        width: 32%;
        height: 18.5rem;
    
    }

    .funcImg{
        top: 86rem;
        width: 23rem;
        height: 28rem;
    }    

    .addBackground{
        width: 45rem;
        padding-left: 10px;
    }


    .addSubContainer02{
        width: var(--mainWidth);
        display: flex;
    
    }

    .add02Title{
        margin-left: 2rem;
    }

    .add02Title h5{
        width: 29.009rem;

    }

    
    .add02Title h4{
        margin-top: 0px;
        width: 29.009rem;

    }

    .add02Image{
        margin-top: 2.434rem;
        width:  19.4914rem;
        height: 16.195rem;
    }

    .addSubContainer02{
        width: var(--mainWidth);
        margin-left: -2.5rem;
        
    }

    
    .add02Image{
        width: 19.491rem;
        height: 16.195rem;
    }
    
    .add02Title{
        width: 23rem;
    }
    
    .add02Title h4{
        font-size: 20px;
    }

    .add02Title h5{
        width: 23rem;
        line-height: 24px;
    }

    .carouselTitle{
        width: var(--mainWidth);
        
    }

    .carouselTitle h4{
        font-size: 20px;
        
    }


    .carouselContent{
        width: var(--mainWidth);
        height: 19rem;

    }

    .carousel{
        width: 21.5rem;
        height: 15.5rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;

    }

    .carouselLeft{
        width: 21.75rem;
        height: 15.5rem;
    }

    .carouselLeft h3{
        font-size: 19px;

        width: 18.75rem;
    }
    
    .carouselLeftAuthor h5{
        font-size: 14px;
        width: 90%;
    }

    .partnersArea{
        margin-top: 8rem;
    }

    .partnersContainer{
        width: var(--mainWidth);

    }

    .partner img{

        padding-left: 2.5rem;
        padding-right: 2.5rem;

    }

    .partner h4{
        font-size: 14px;
    }

    .partner h5{
        font-size: 12px;
    }

    .formTitle h4{
        font-size: 20px;
    }
}

@media (max-width: 757px) {

    :root {
        --mainWidth: 36rem;

    }

    .subDivLandingTitle{
        position: absolute;
        width: 23.688rem;
        top: 6.31rem;
        left: 2.06rem;
         z-index: 2;
    }

    .backgroundImgLanding{
        top: 14rem;
        width: 24.438rem;
        height: 21.688rem;
    }

    .subDivLanding img{
        height: 24.875rem;
        width: 100%;
    
    }

    .subDivLanding{
        height: 25rem;
    }
    

    .subDivLandingTitle h4{

        width: 18rem;
        font-size: 24px;

    }

    .subDivLandingTitle h5{
        line-height: 20px;
    }

    .googlePlay{
        height: 2.5rem;
        width: 8rem;
    }

    .appStore{
        height: 2.5rem;
        width: 7rem;
    }

    .download{
        margin-top: 1rem;
    }

    .descrptContainer{  
        top: -9rem;
        display: flex;
        justify-content: center;
        width: 100%;

    }


    .descrpt{
        margin-left: 0px;
        width: 96%;
        height: 17.625rem;
    }

    .descrpt h4{
        width: 28rem;
        font-size: 20px;
        margin-top: -1rem;
        margin-left: 1.5rem;
    }

    .descrpt h5{

        width: 90%;
        margin-left: 1.5rem;
    }
   
    .atltDescrpt{
        margin-top: 9.8rem;
        right: 5px;
        width: 9.875rem;
        height: 11.148rem;
    }

    .downloadDescriptArea{
        margin-left: 1.5rem;
    }

    .googlePlayBlack{
        margin-left: 1.5rem;
  
    }

    .functionsTitle {
        margin-top: 49.25rem;

    }

    .functionsTitle h4{
        font-size: 20px;
    }
    

    .functionContainer{
        width: 90%rem;
    }

    .functionContainer02{
        width: 44rem;
        max-width: 63.875rem;
        display: flex;
        margin-top: 2.5rem;
        justify-content: space-between;
    
    }

    .placeHolderFunc{
        width: 14rem;
        height: 17.5rem;
    
    }

    .funcImg{
        top: 94rem;
        width: 26rem;
        height: 24.5rem;
    }   
    
    .addContainer01{
        margin-top: 14em;
    }



    .addBackgroundContainer{
        margin-top: 0rem;
    }

    .addBackground{
        width: 34rem;
        height: 32rem;
        padding-left: 10px;
    }

    .addTextSubContainer h4{
        font-size: 56px;
    }

    
    .addTextSubContainer h5{
        font-size: 20px;
    }

    .addTextSubContainer{
        margin-top: -11rem;
        margin-left: 2rem;
        margin-right: 0.25rem;
    }

    .addBackground{
        padding-right: 0px;
        display: flex;
        justify-content: center;
    }

    .addTextContainer{
        margin-left: -0.5rem;
    }



    .addSubContainer02{
        width: var(--mainWidth);
        display: flex;
        flex-direction: column;
    
    }

    .add02Title{
        margin-left: 2rem;
    }

    .add02Title h5{
        width: 29.009rem;

    }

    
    .add02Title h4{
        margin-top: 0px;
        width: 29.009rem;

    }

    .add02Image{
        margin-top: 2.434rem;
        width:  19.4914rem;
        height: 16.195rem;
    }

    .addSubContainer02{
        width: 100%;
        margin-left: -2.5rem;
        
    }

    .add02ImageContainer{
        margin-top: 6rem;
        flex-direction:row;
        width: 100%;
        position: relative;

    }

    .add02Image{
        width: 19.491rem;
        position: absolute;
        height: 16.195rem;
        left: 0;
        right: 0;
        margin: auto;
        transform: translateY(-50%);

    }
    
    .add02Title{
        text-align: center;
        justify-content: center;
        margin-top: 8rem;
        width: 96%;
    }
    
    .add02Title h4{
        font-size: 20px;
        width: 96%;
    }

    .add02DownloadArea{
        display: flex;
        justify-content: center;
        margin-bottom: 0.5rem;
    }

    .add02Title h5{
        width: 23rem;
        line-height: 24px;
        width: 96%;
    }

    .carouselTitle{
        width: 93%;
        
    }

    .carouselStar{

        margin: auto;
        margin-left: -3rem;
        margin-top: 22rem;
        left: 0;
        right: 52rem;
        transform: translateY(-40%);
    }
    
    .carouselTitle h4{
        font-size: 20px;
        
    }


    .carouselContent{
        width: 96%;
        margin: 0px;
        margin-top: 2.5rem;
        height: 13rem;


    }

    .carousel{

        width: 46%;
        margin-left: 0.7rem;
        margin-right: 0.7rem;
        height: 11.938rem;

        
    }

    .carouselLeft{
        margin-left: 1rem;
        height: 10.938rem;
    }

    .carouselLeft h3{
        font-size: 16px;
        margin-left: 0px;
        width: 97%;
    }

    .carouselLeftAuthor{
        height: 3rem;
    }

    .carouselLeftAuthor img{
        width: 2.5rem;
        height: 2.5rem;
    }
    
    .carouselLeftAuthor h4{
        margin-top: 1px;
        font-size: 14px;
    }

    .carouselLeftAuthor h5{
        font-size: 11px;
    }

    .partnersArea{
        margin-top: 5rem;
    }

    .partnersContainer{
        width: 93%;
    }

    .partner img{
        padding-left: 2.2rem;
        padding-right: 2.2rem;
    }

    .formTitle h4{
        font-size: 20px;
    }

    .sucessMsg{
        width: 90%;
    }

    .failMsg{
        width: 90%;
    }
}

@media (max-width: 628px) {

    .subDivLanding img{
        width: 100%;
        height: 40.875rem;
    }

    .subDivLandingTitle{
        margin: 0;
        padding: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 0.0rem;
        text-align: center;
    
    }
    
    .subDivLanding{
        height: 40.8rem;
    }

    .subDivLandingTitle h4{
        font-family: "Montserrat";
        margin: 0;
        width: 85%;
        line-height: 40px;
        font-size: 24px;
    }

    .subDivLandingTitle h5{
        font-family: "Roboto";
        margin: 0;
        margin-top: 1.5rem;
        width: 75%;
        line-height: 21px;
        font-size: 16px;
    }

    .backgroundImgLanding{
        margin: auto;
        margin-top: 18.8rem;
        height: 16rem;
        width: 18rem;
        transform: translateY(-50%);
    }

    .download{
        margin-top: 1.5rem;
    }
    .googlePlay{
        margin-top: 0.5rem;
        height: 40px;
        width: 135px;
    }

    .appStore{
        height: 40px;
        width: 120px;
    }

    .descrptArea{
        margin-top: 50.5rem;
        height: 22rem;
    }

    .descrptContainer{
        top: 13rem;
        height: 22rem;
    }

    .atltDescrpt{
        left: 0;
        right: 0;
        width: 24.813rem;
        height: 10.813rem;
        padding-right: 15px;
        margin: auto;
        margin-top: -3.5rem;
        transform: translateY(-50%);
    }

    .descrpt{
        text-align: left;
        width: 92%;
        height: 22rem;
    }

    .descrpt h4{
        font-size: 20px;
        width: 100%;
        margin: 0;
        margin-left: 2rem;
    }

    .descrpt h5{
        font-size: 16px;
        width: 86%;
        margin: 0;
        line-height: 24px;
        margin-top: 1.5rem;
        margin-left: 2rem;
        font-weight: 500;
        font-family: "Roboto Flex";
    }

    .functionsTitle{
        margin-top: 7rem;
    }

    .functionsTitle h4{
        font-size: 32px;

    }

    .functionsTitle h5{
        font-size: 22px;
        width: 86%;
        line-height: 30px;
        font-family: "Roboto Flex";

    }

    .addContainer01{
        margin-top: 0rem;
        width: 100%;
        height: 42.885rem;
    }

    .addBackgroundContainer{

        margin-top: 0px;
        width: 100%;

    }

    .addBackground {
        height: 42rem;
        width: 28rem;
        left: 0;
        padding-left: 0px;
        top: 21rem;
        right: 0;
        margin: auto;
        transform: translateY(-50%);
    }

    .addTextContainer{
        padding-top: 5rem;
        height: 22rem;
        margin-bottom: 0px;
        flex-direction: column;
    }

    .addBackgroundContainer{
        margin-top: 0px;
        height: 42.885rem;
        top: 0px;
    }
    
    .addTextSubContainer{
        height: 8rem;
        width: 90%;
        margin-top: 0.5rem;
    }

    .addTextSubContainer h4{
        margin: 0px;
        width: 100%;
        margin-top: 1rem;
    }

    .addTextContainer h5{
        margin: 0px;
        width: 100%;
        font-size: 25px;
    }

    .addDownloadAreaContainer{
        margin-top: 0px;
    }

    .addMainContainer02{
        margin-top: 8rem;
    }

    .add02Image{
        width: 28.5rem;
        height: 21.813rem;
        padding-right: 0.2rem;
        left: 0;
        right: 0;
        margin: auto;
        transform: translateY(-50%);
    
    }

    .add02Title{
        margin-top: 11rem;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

    .add02Title h4{
        width: 82%;
        font-size: 20px;
    }

    .add02Title h5{
        width: 82%;
        font-size: 16px;
        line-height: 24px;
    }

    .add02DownloadContainer{
        margin-top: 0rem;
    }

    .carouselContainer{
        margin-top: 3rem;
    }

    .carouselContent{
        margin-top: 1rem;
        width: 100%;
        height: 15rem;
        overflow-x:auto;
        justify-content: left;
    }

    .carouselContent::-webkit-scrollbar{
        display: none;
      }

    .carouselTitle{
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .carouselStar{

        height: 7rem;
        width: 7rem;
        margin-left: -1.5rem;
        margin-top: 17rem;

    }

    .carouselTitle h4{
        text-align: center;
        line-height: 30px;
        width: 80%;
        font-size: 20px;
    }

    .carousel{

        margin-left: 1.5rem;
        padding: 0;
        width: 18.406rem;
    }

    .partnersArea{
        margin-top: 4rem;
    }

    .partner img{
        height: 4rem;
        width: 4rem;
    }

    .partnersContainer{
        overflow-x: auto;
        width: 100%;
    }

    .partnersContainer::-webkit-scrollbar{
        display: none;
    }

    .partner h4{
        font-size: 14px;
        font-family: "Roboto Flex";

    }

    .partner h5{
        font-size: 10px;
        
    }

    .formTitle h4{
        font-size: 20px;
    }

    .formTitle h5{
        font-size: 16px;
    }
}



@media (max-width: 500px) {

    .googlePlayBlack{
        height: 3rem;
        width: 9rem;
    }

    .appStoreBlack{
        height: 3rem;
        width: 8.5rem;
    }
    
    .googlePlayMain{
        height: 3rem;
        width: 9rem;
    }

    .appStoreMain{
        height: 3rem;
        width: 8.5rem;
    }


    .descrpt{

        height: 24rem;
    }
    
    .atltDescrpt{
        width: 22rem;
        height: 9rem;
        padding-right: 0rem;
    }

    .functionsTitle{
        margin-top: 9rem;
        width: 98%;
    }

    .functionsTitle h4{
        font-size: 20px;
    }

    .functionsTitle h5{
        font-size: 14px;
        line-height: 20px;
    }

    .addContainer01{
        margin-top: -4rem;
    }


    .addBackground{
        padding-left: 0px;
    }

    .addMainContainer02{
        margin-top: 0rem;
        height: 34rem;
    }

    .addSubContainer02{
        margin-left: 0px;
        
    }

    .add02Title{
        width: 100%;
        margin-left: 0px;

    }

    .add02Title h4{
        width: 87%;
    }

    .add02Image{
        width: 22.5rem;
        height: 16.813rem;


    
    }
    
    .partnersArea{
        margin-top: 1.5rem;
    }

    .add02Title{
        margin-top: 8rem;
    }

    .carouselTitle{
        width: 100%;
    }

    .carouselTitle h4{
        width: 90%;
        font-size: 20px;
    }

    .formContainer{
        margin-top: 4.25rem;
    }

    .formTitle{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .formArea{
        width: 94%;
        align-items: center;
        padding-top: 0px;
        height: 44rem;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .formTitle h4{
        font-size: 20px;
    }   
    
    .formTitle h5{
        width: 85%;
        font-size: 14px;
    }

    .formArea input{
        width: 85%;
        margin-left: 0rem;
        margin-top: 2rem;
        height: 3rem;

    }
    
    .formButtonContainer{
        width: 100%;


    }

    .addBackground{
        height: 34rem;
        width: 22rem;
    }

    .formArea textarea{
        width: 87.5%;
        margin-left: 0rem;
        margin-top: 2rem;


    }



    .formAreaOne{
        margin-top: 0rem !important;
    }

    .sucessMsg{
        font-size: 17px;
    }

    .failMsg{
        font-size: 17px;
    }


}

@media (max-width: 400px) {


    .addBackground{
        padding-left: 0px;
    }

    .subDivLandingTitle h4{
        width: 95%;
        font-family: "Montserrat";
        font-size: 21px;
        font-weight: 700;
    }

    .subDivLandingTitle h5{
        width: 90%;
    }

}






